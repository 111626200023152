@use '../../../utils/styles/default.scss';
@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.clans-section {
  max-width: 900px;
  margin: 0 auto;
  &-wrapper {
    padding: 3% 20px 0%;
    background-position: bottom;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 2rem;
    @include media(sm) {
      padding: 0 1rem;
      width: 33%;
    }
    @include media(lg) {
      width: 33%;
      padding: 0 2rem;
    }
    @include media(xl) {
      padding: 0 2rem;
    }
  }
  &__img {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
}
