@use '../../../utils/styles/default.scss';
@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.gamezone {
  display: flex;
  justify-content: center;
  align-items: center;
  @include media(md) {
    max-width: 70vw;
    margin: 0 auto;
  }
  &-section {
    background-position: center bottom;
    background-size: cover;
    position: relative;
    padding-top: 8%;
    padding-bottom: 0%;

    @include media(md) {
      padding-top: 10%;
    }
  }
  &::before {
    content: '';
    display: block;
    background-image: url('../../../assets/images/rocks.png'),
      url('../../../assets/images/rocks-flipped.png'),
      url('../../../assets/images/rocks-flipped.png');
    background-position: center bottom, calc(50% + 1920px) bottom, right calc(50% + 1920px) bottom;
    transform: translateY(10%);
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 3100px;
    @include media(xxxl) {
      height: 4000px;
    }
    background-size: 1920px;
    bottom: -126px;
    z-index: -1;
    transform: translateY(139px);
  }
}
