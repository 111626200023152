@import './colors.scss';

*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

button {
  font-family: inherit;
  cursor: pointer;
  font-size: inherit;
}

.rnc__base {
  top: 0;
}

.rnc__notification-container--top-full {
  height: 100%;
  pointer-events: none;
  .rnc__notification {
    height: 100% !important;
    width: 100% !important;
    .rnc__notification-item {
      height: 100% !important;
      --animate-duration: 200ms !important;
    }
  }
}

.page-wrapper {
  &.loading::before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/icons/loading.gif'), $black;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    z-index: 1000;
  }
}

.main-content {
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 700px;
}

.col {
  width: 50%;
  padding: 1rem;
  &.fluid {
    width: 100%;
  }
}

.default-btn {
  background-color: rgb(18, 0, 59);
  border: 1px solid rgb(9, 0, 31);
  padding: 0.5rem;
  border-radius: 3px;
  display: block;
  margin-bottom: 1rem;
  color: white;
}

.default-value {
  font-style: 1.5rem;
  display: block;
  margin-bottom: 1rem;
}

.h-100 {
  height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-container {
  margin: 0 auto;
  padding: 0 2rem;
  &--md {
    max-width: 75rem;
  }
  &--lg {
    max-width: 100rem;
  }
  &--xl {
    max-width: 110rem;
  }

  @media screen and (max-width: 1100px) and (min-width: 815px) {
    padding: 0 10px;
  }
}

.section-full-height {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100vh;
}

.btn-unstyled {
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
}

.invisible {
  opacity: 0;
  position: absolute;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  z-index: -1;
  pointer-events: none;
  visibility: hidden;
}

.d-none {
  display: none;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
