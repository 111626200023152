@use '../../../utils/styles/default.scss';
@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.join-us {
  z-index: 2;
  transform: translateY(10%);
  &-section {
    background: #060606;
    position: relative;
    z-index: 2;
    overflow-x: hidden;
  }
  &__header {
    text-align: center;
    color: $purple;
    text-transform: uppercase;
  }
  &__discord-icon {
    width: 2rem;
    height: 2rem;
    @include media(md) {
      width: 6rem;
      height: 6rem;
    }
  }
  &__space {
    max-width: 1920px;
    margin: 0 auto;
    &::before {
      content: '';
      position: absolute;
      display: block;
      padding-bottom: calc((9 / 18) * 100%);
    }
  }
  &__footer {
    // margin-top: -5%;
    overflow: hidden;
    z-index: -1;
  }
}

.footer-img {
  width: 75%;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin-left: 50%;
  transform: translateX(-45.5%) scale(1.3);
  @include media(md) {
    transform: translateX(-45.5%);
  }
  max-width: 1920px;
}
