@import '../../utils/styles/mixin.scss';
@import '../../utils/styles/colors.scss';
@import '../../utils/styles/typography.scss';

body {
  background-color: $brown;
}

.header {
  position: fixed;
  width: 70vw;
  z-index: 5;
  right: 0;
  background-color: $black;
  height: 100vh;
  right: -100%;
  transition: right 0.3s ease;

  @include media(md) {
    background-color: transparent;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1em 0;
    color: white;
    font-size: 11px;
    transition: 0.3s ease background-color;
    z-index: 5;
    height: initial;
  }

  @include media(lg) {
    font-size: 13px;
    padding: 1rem 1rem;
  }

  @include media(xl) {
    font-size: 20px;
  }

  &--scroll {
    background-color: $black;
  }

  &--scroll~.burger-btn {
    top: 1.5rem;
    right: 1.5rem;
  }

  &--scroll &__content {
    @include media(xl) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &--opened+.header-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    pointer-events: all;
  }

  &--opened {
    right: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    z-index: 3;

    @include media(md) {
      transition: 0.3s ease padding-left, 0.3s ease padding-right;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
  }

  &__social {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-transform: uppercase;

    @include media(md) {
      flex-direction: row;
      padding: 0 0 0 0.5rem;
    }

    @include media(lg) {
      font-display: row;
      padding: 0 1rem;
    }

    a {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      text-decoration: none;
      color: $white;
      font-size: 16px;

      @include media(md) {
        margin-bottom: 0;
      }
    }

    span {
      @include media(md) {
        display: none;
      }
    }

    img {
      margin-right: 0.5rem;
      height: 1.5rem;
      width: 1.5rem;
      transition: opacity 0.2s ease;

      @include media(md) {
        width: 1.5rem;
        height: 1.5rem;

        &:hover {
          opacity: 0.7;
        }
      }

      @include media(lg) {
        width: 1.75rem;
        height: 1.75rem;
      }

      @include media(xl) {
        margin-right: 1.5rem;
      }
    }
  }
}

.header-nav {
  display: flex;
  flex-direction: column;
  color: $white;
  padding: 1rem 0;
  font-size: 18px;

  @include media(md) {
    flex-direction: row;
    padding: auto;
    max-width: 900px;
    width: 55%;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    margin-bottom: 1rem;
    display: block;
    color: inherit;
    text-decoration: none;
    margin-right: 1em;
    font-size: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 0.2s ease color;
    white-space: nowrap;
    @extend .text-uppercase;

    @include media(md) {
      margin-bottom: initial;
    }

    &:hover {
      color: $yellow;
    }

    &-eyes-cursor {
      cursor: url('../../assets/images/eyes_1f440.png'), auto;
    }
  }
}

.connect-btn {
  order: -1;
  font-size: inherit;
  padding: 0.5rem;
  border-radius: 3px;
  display: block;
  color: white;
  background-color: transparent;
  border: 1px solid transparent;
  @extend .text-uppercase;
  transition: 0.1s ease background-color;
  background-color: $yellow-dark;
  margin-bottom: 1rem !important;

  @include media(md) {
    margin-bottom: initial !important;
    order: initial;
    margin-bottom: initial;
    background-color: transparent;
  }

  &:hover {
    background-color: $yellow-dark;
  }
}

.burger-btn {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 2rem;
  right: 2rem;
  transition: top 0.4s ease, right 0.4s ease;
  width: 1.5rem;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 4;

  @include media(md) {
    display: none;
  }

  >span {
    display: block;
    margin: 2px 0;
    width: 100%;
    height: 2px;
    background-color: $white;
  }
}

.header-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  pointer-events: none;
  transition: 0.2s ease background-color;

  @include media(md) {
    display: none;
  }
}

.button-box {
  display: flex;
  position: relative;
  bottom: 4px;
  align-items: center;
  column-gap: 10px;

  .swiched-bg {
    background: #41439c;
    transition: 0.3s;

    .swich-icon-bg {
      transform: translate(22px, 0);
    }
  }
}

.swich-button-bg {
  background: #dee8f2;
  box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.4);
  border-radius: 35px;
  width: 50px;
  height: 27px;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.3s;


  .swich-icon-bg {
    background: linear-gradient(129.58deg, #e6eef8 0%, #ffffff 111.48%);
    box-shadow: 5px 2px 16px rgba(136, 165, 191, 0.78), inset 0px -1px 1px rgba(85, 102, 123, 0.4);
    border-radius: 35px;
    width: 25px;
    height: 25px;
    position: relative;
    margin: 1px;
    transition: 0.3s ease-in-out;
  }


}

@media screen and (max-width: 1100px) and (min-width: 815px) {
  .swich-button-bg {
    width: 46px;
    height: 25px;
  }

  .swich-icon-bg {
    width: 23px !important;
    height: 23px !important;
  }

  .header-nav {
    font-size: 13px !important;
  }
}