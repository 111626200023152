@import './mixin.scss';

body {
  font-family: 'Pixellari';
  font-size: 20px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.system-font {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI Emoji', 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.heading-1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
  @include media(sm) {
    font-size: 24px;
  }
  @include media(md) {
    font-size: 44px !important;
  }
  // @include media(xxl) {
  //   font-size: 55px !important;
  // }
}

.heading-2 {
  text-transform: uppercase;
  font-size: 19px;
  line-height: 140%;
  @include media(sm) {
    font-size: 22px;
  }
  @include media(md) {
    font-size: 28px;
  }
  // @include media(xxl) {
  //   font-size: 32px !important;
  // }
}

.default-text {
  text-transform: uppercase;
  line-height: 140%;
  font-size: 16px !important;
  @include media(md) {
    font-size: 20px !important;
  }
}
