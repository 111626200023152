$mediaSizes: (
  'xs': 12rem,
  'sm': 36rem,
  'md': 48rem,
  'lg': 62rem,
  'xl': 75rem,
  'xxl': 90rem,
  'xxxl': 121rem
);

@mixin media($size, $max: false) {
  // 2nd ARG AS TRUE TO LAPTOP FIRST
  @each $deviceSizeName, $deviceSizeValue in $mediaSizes {
    @if ($size == $deviceSizeName) {
      @if ($max == false) {
        @media (min-width: $deviceSizeValue) {
          @content;
        }
      }
      @if ($max == true) {
        @media (max-width: calc(#{$deviceSizeValue} - #{rem(1)})) {
          @content;
        }
      }
    }
  }
}

$sides: ('top', 'bottom', 'left', 'right');

$spaceAmounts: (
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '1rem',
  '4': '1.25rem',
  '5': '1.5rem',
  '6': '2rem',
  '7': '3rem',
  '8': '4rem',
  '10px': '10px',
  '15px': '15px',
  '20px': '20px',
  '30px': '30px',
  '40px': '40px',
  '50px': '50px',
  '60px': '60px',
  '70px': '70px',
  '80px': '80px',
  '100px': '100px',
  '120px': '120px'
);

@each $spaceTitle, $space in $spaceAmounts {
  .m-#{$spaceTitle} {
    margin: #{$space};
  }
  .p-#{$spaceTitle} {
    padding: #{$space};
  }
}

@each $spaceTitle, $space in $spaceAmounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$spaceTitle} {
      margin-#{$side}: #{$space};
    }
    .p#{str-slice($side, 0, 1)}-#{$spaceTitle} {
      padding-#{$side}: #{$space};
    }
  }
}

@each $spaceTitle, $space in $spaceAmounts {
  @each $side in $sides {
    @each $size, $i in $mediaSizes {
      .m#{str-slice($side, 0, 1)}-#{$size}-#{$spaceTitle} {
        @media screen and (min-width: $i) {
          margin-#{$side}: #{$space};
        }
      }
      .p#{str-slice($side, 0, 1)}-#{$size}-#{$spaceTitle} {
        @media screen and (min-width: $i) {
          padding-#{$side}: #{$space};
        }
      }
    }
  }
}
