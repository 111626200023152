@use '../../../utils/styles/typography.scss';
@import '../../../utils/styles/colors.scss';
@import '../../../utils/styles/mixin.scss';

.how-it-started {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  &-section {
    color: $white;
    text-transform: uppercase;
    padding: 30% 0 15%;
    position: relative;
    background-size: 100%;
    background-repeat: no-repeat;
    @include media(md) {
      padding: 8% 0 2%;
    }
    &::before {
      content: '';
      display: block;
      top: 0;
      height: 175vh;
      width: 100%;
      position: absolute;
      background-size: 100%;
      background-position: top 25vh;
      z-index: -1;
    }
    @include media(md) {
      font-size: 30px;
    }
  }
  &__title {
    @extend .heading-1;
  }
  &__underline {
    position: relative;
    @include media(md) {
      padding-bottom: 2rem;
      margin-bottom: 2rem;
    }
    &::after {
      content: '';
      position: absolute;
      width: 80%;
      height: 4px;
      background-color: $purple;
      left: 50%;
      transform: translateX(-50%);
      bottom: 1rem;
      display: none;
      @include media(md) {
        display: block;
      }
    }
  }
  &__highlight {
    @include media(md) {
      color: $purple;
    }
  }
}
