$header-height: 70px;
@import '../../../utils/styles/mixin.scss';

@keyframes heroTitleAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero {
  &-section {
    background-image: url('../../../assets/images/hero-xl.gif');
    background-image: url('../../../assets/images/hero-xl.webp');
    background-position: bottom center;
    background-size: cover;
    min-height: 700px;
    height: 100vh;
    padding-top: $header-height;
    color: white;
    position: relative;
    &::before {
      content: '';
      display: block;
      height: 100px;
      background-image: url('../../../assets/images/hero-join.jpg');
      background-size: cover;
      width: 100%;
      bottom: 0;
      position: absolute;
      transform: translateY(100%);
      background-repeat: no-repeat;
    }
  }
  &__title-img {
    width: 100%;
    opacity: 0;
    animation: heroTitleAppear 1s ease 2s forwards;
    aspect-ratio: 400 / 700;
    @include media(md) {
      aspect-ratio: 2304 / 1296;
    }
  }
  &__main-title {
    font-size: 6rem;
    font-weight: normal;
    display: none;
    &-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
