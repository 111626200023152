@import '../../utils/styles/mixin.scss';
@import '../../utils/styles/colors.scss';

@keyframes glowingBlinking {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
}

.map {
  width: 100%;
  position: relative;
  padding-bottom: calc((1280 / 2194) * 100%);
  z-index: 3;
  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__zones {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    svg {
      width: 100%;
    }
  }
  &__zone1 {
    top: 10%;
    left: 15.5%;
    width: 18%;
    position: absolute;
    z-index: 2;
    cursor: url('../../assets/icons/swords.png') 1.5 1.5, auto;
  }
  &__zone2 {
    top: 5%;
    right: 16.3%;
    width: 33.6%;
    position: absolute;
    z-index: 2;
    cursor: url('../../assets/icons/snowflake.png') 1.5 1.5, auto;
  }
  &__zone3 {
    bottom: 3.1%;
    right: 4.2%;
    width: 56.9%;
    position: absolute;
    z-index: 2;
    cursor: url('../../assets/icons/bow.png') 1.5 1.5, auto;
  }
  &__zone4 {
    bottom: 12.7%;
    left: 23.3%;
    width: 8.9%;
    position: absolute;
    z-index: 2;
    cursor: url('../../assets/icons/pill.png') 1.5 1.5, auto;
  }
  &__zone1,
  &__zone2,
  &__zone3,
  &__zone4 {
    transition: opacity 0.4s ease;
    opacity: 0;
    animation: glowingBlinking 1.5s linear forwards infinite;
    &:hover {
      animation: none;
      opacity: 1;
    }
  }
}

.modal-map-field {
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  &.loading {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: url('../../assets/icons/loading.gif'), $black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16vw;
      border-radius: 5vw;
      z-index: 3;
    }
  }
  &__content {
    position: relative;
    padding-bottom: calc((1050 / 1466) * 100%);
    width: 100%;
  }
  &__table {
    position: absolute;
    top: 21%;
    left: 20%;
    width: 60%;
    z-index: 2;
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  &__row {
    position: relative;
    margin-bottom: 2%;
    > div {
      text-align: center;
    }
    .nft-image {
      margin-right: 0;
      text-align: center;
    }
    .button-next,
    .button-prev {
      position: absolute;
      top: 50%;
      height: 8vw;
      padding: 0 1vw;
      width: 4vw;
      transition: 0.2s ease opacity;
      &:disabled {
        display: none;
      }
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 100%;
      }
    }
    .button-prev {
      left: 0;
      transform: translateY(-50%) translateX(-100%);
      outline: none;
      border: none;
      background-color: transparent;
    }
    .button-next {
      right: 0;
      transform: translateY(-50%) translateX(100%);
      outline: none;
      border: none;
      background-color: transparent;
    }
  }
}

.modal-map-table {
  text-transform: uppercase;
  font-weight: bold;
  &__title {
    text-align: center;
    font-size: 10px;
    margin-bottom: 0.1em;
    font-size: 2.1vw;
    @include media(md) {
      font-size: 1.8vw;
    }
  }
  &__row-title {
    padding-left: 5%;
    font-size: 1.2vw;
  }
  &__header {
    display: flex;

    border-bottom: 1px solid black;
    text-transform: uppercase;
    margin-bottom: 2%;

    font-size: 1.8vw;
    @include media(md) {
      border-width: 2px;
      font-size: 1.4vw;
      padding: 0 0.5rem;
    }
    &-row {
      width: 50%;
      padding: 0 0.5rem;
      > div {
        margin-bottom: 0.5rem;
      }
    }
  }
  &__content {
    font-size: 0.8vw;
    &:last-child {
      padding: 0.5rem;
      @include media(md) {
        padding: 1rem;
      }
    }
  }
  &__staked-title {
    margin: 0.2vw 0 0.3vw;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem;
    text-transform: uppercase;
    @include media(md) {
      padding: 0.2rem 1rem;
    }
  }
  .btn-outline {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    background-color: transparent;
    transition: background-color 0.2s ease, color 0.2s ease;
    text-transform: uppercase;
    padding: 0.5em 1em;
    font-size: 1.4vw;
    font-weight: bold;
    @include media(md) {
      padding: 1em 1.5em;
      height: 40px;
      border-width: 3px;
      font-size: 1.3vw;
    }
    &:hover {
      background-color: black;
      color: #fff;
    }
    &:disabled {
      cursor: default;
      color: black;
      background-color: transparent;
      opacity: 0.5;
    }
  }
  .nft-image {
    border-radius: 50%;
    width: 3.9vw;
    height: 3.9vw;
    background-color: rgb(104, 104, 104);
    transition: 0.2s ease box-shadow, 0.2s ease border;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 0.1vw 0.1vw $white;
    }
    &.selected {
      box-shadow: 0 0 0.7vw 0.4vw $white;
    }
  }
  &.main-table {
    .swiper-wrapper {
      padding: 2% 0 0 1rem;
    }
  }
}
