$black: #000;
$white: #fff;

$blue: #3f4acc;
$purple: #41439c;
$yellow: yellow;
$yellow-dark: rgb(175, 145, 10);
$brown: #1c1918;

$colors: (
  'blue': $blue,
  'white': $white
);

@each $color-title, $color-value in $colors {
  .text-#{$color-title} {
    color: #{$color-value};
  }
  .bg-#{$color-title} {
    background-color: #{$color-value};
  }
}
