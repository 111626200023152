@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.footer {
  &-section {
    background: $black;
    padding: 0.2rem 0;
    @include media(md) {
      padding: 0.2rem 0 1rem;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    color: $white;
    text-transform: uppercase;
    font-size: 12px !important;
    @include media(md) {
      font-size: 16px !important;
    }
  }
  &__agreement,
  &__token {
    flex-grow: 1;
    @include media(md) {
      width: 33%;
    }
  }
  &__token {
    text-align: right;
  }
}
