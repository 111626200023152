@import '../../utils/styles/mixin.scss';

.btn-goBack {
  position: absolute;
  z-index: 2;
  top: 3.5%;
  left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.2s ease, color 0.2s ease;
  color: white;
  text-transform: uppercase;
  font-size: 1.6vw;
  font-weight: bold;
  border: none;
  @include media(md) {
    height: 40px;
    font-size: 1.3vw;
  }

  &:disabled {
    cursor: default;
    color: black;
    background-color: transparent;
    opacity: 0.5;
  }
}
