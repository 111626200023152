@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.migrate-section {
  background-image: linear-gradient(0deg, rgb(25, 25, 29), rgba(25, 25, 29, 0));

  @include media(xl) {
    padding: 17% 0 0%;
  }

  @include media(xxxl) {
    padding: 8% 0 0%;
  }

  @include media(xs) {
    padding: 10% 0 0%;
  }

  .migrate-container {
    text-transform: uppercase;

    .migrate-title {
      text-align: center;
      font-size: 24px;
      color: $purple;
      margin-bottom: 50px;

      @include media(xxxl) {
        font-size: 70px !important;
      }

      @include media(md) {
        font-size: 44px !important;
      }
    }

    .migrate-box {
      display: flex;
      column-gap: 30px;
      row-gap: 30px;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1100px;
      margin: 0 auto;

      .migrate-button {
        width: 90%;
        max-width: 500px;
        height: 80px;
        background: #ffe040;
        background: -webkit-linear-gradient(right, #ffe040, #FBD601);
        background: -moz-linear-gradient(right, #ffe040, #FBD601);
        background: linear-gradient(to left, #ffe040, #FBD601);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $black;
        border-radius: 5px;
        cursor: pointer;
        font-size: 25px;

        @include media(md) {
          font-size: 30px !important;
        }

        &:hover {
          box-shadow: -9px 7px 10px 2px rgba(0, 0, 0, 0.2) inset;
        }

        &:active {
          transform: scale(.97);
        }
      }

      .disable {
        background: gray !important;
        background: -webkit-linear-gradient(right, lightgray, darkgray) !important;
        background: -moz-linear-gradient(right, lightgray, darkgray) !important;
        background: linear-gradient(to left, lightgray, darkgray) !important;

        &:hover {
          box-shadow: -9px 7px 10px 2px rgba(0, 0, 0, 0.2) inset;
        }
      }
    }
  }
}

.modal-map-field {

  .modal-map-field__content {

    .modal-map-migrate__table {
      position: absolute;
      height: 100%;
      width: 100%;


      .migrate-table {
        position: absolute;
        top: 21%;
        left: 20%;
        z-index: 2;
        width: 60%;

        .migrate-header {
          margin-top: 20px;

          span {
            margin-left: 5px;
          }
        }

        .migrate__row {

          .nft-item {
            display: flex;
            grid-column-gap: 10px;
            column-gap: 10px;
            align-items: center;
            margin-left: 20%;
            min-height: 6em;
            min-width: 100%;
          }

        }
      }

      .modal-map-field__img {
        height: 100%;
        width: 100%;
      }
    }
  }
}