@mixin addFont($name, $weight: 400, $style: normal) {
  @font-face {
    font-family: 'Pixellari';
    src: url('../../assets/fonts/' + $name + '.ttf');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@include addFont('Pixellari', 400);
