@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.team {
  &-section {
    &::before {
      position: absolute;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      z-index: -1;
    }
    background: url('../../../assets/images/eyes-bg.webp');
    background-size: 100% auto;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 0;
    @include media(md) {
      padding-top: 390px;
    }
    @include media(xl) {
      padding: 7% 0;
    }
  }
  &__heading {
    margin-bottom: 2rem;
    @include media(md) {
      margin-bottom: 4rem;
    }
  }
  &__cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
  }
}

.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 2rem;
  padding: 0 2%;
  width: 33%;
  @include media(md) {
    margin: 0 0 5rem;
  }
  &__img {
    width: 100%;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
  }
  &__title {
    text-transform: uppercase;
    text-decoration: none;
    color: $blue;
    display: flex;
    align-items: center;
    transition: 0.2s ease opacity;
    font-size: 16px;
    margin-top: 0.2rem;
    @include media(md) {
      margin-top: 1rem;
      font-size: 24px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &__title-icon {
    width: 1rem;
    height: 1rem;
    display: block;
    margin-left: 0.5rem;
    margin-bottom: 0.4rem;
    @include media(md) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
