@use '../../../utils/styles/default.scss';
@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.phases {
  &-section {
    background: url('../../../assets/images/stalactites.gif'),
      url('../../../assets/images/eyes-bg.gif'), #060606;
    background: url('../../../assets/images/stalactites.webp'),
      url('../../../assets/images/eyes-bg.webp'), #060606;
    background-size: 100vw auto;
    background-repeat: repeat no-repeat;
    background-position: top, 50%;
    @include media(xl) {
      padding: 8% 0 0%;
    }
    @include media(xxxl) {
      padding: 8% 0 0%;
      background-size: 1920px auto;
    }
    position: relative;
  }
  &__header {
    text-align: center;
    color: $purple;
    text-transform: uppercase;
    padding-top: 33% !important;
    @include media(md) {
      padding-top: 100px !important;
    }
    @include media(xxxl) {
      padding-top: 15% !important;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    margin-bottom: 24px;
    @include media(xxxl) {
      font-size: 70px !important;
      margin-bottom: 1em;
    }
  }
}

.phases-item {
  border: 3px solid $white;
  background-color: #1f1f22;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 35px;
  padding: 8px;
  max-width: 700px;
  @include media(md) {
    padding: 20px;
    margin-bottom: 75px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    color: $purple;
    margin-bottom: 15px;
  }
  &__text {
    color: $white;
  }
  &::before {
    content: '';
    width: 16px;
    height: 40px;
    border: 2px solid #fff;
    position: absolute;
    bottom: 0;
    transform: translateY(100%) translateX(-9px);
    left: 50%;
    @include media(md) {
      height: 80px;
    }
  }
  &:last-child::before {
    display: none;
  }
}
