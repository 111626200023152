@import '../../utils/styles/mixin.scss';
@import '../../utils/styles/colors.scss';

.dialog {
  position: relative;
  padding-bottom: calc((1050 / 1466) * 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  &.loading {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -10%;
      left: -10%;
      right: -10%;
      bottom: -10%;
      width: 120%;
      height: 120%;
      background: url('../../assets/icons/loading.gif'), $black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16vw;
      border-radius: 5vw;
      z-index: 3;
    }
  }
  &-modal-wrapper .modal {
    @include media(md) {
      max-width: 40vw;
      margin: auto;
    }
  }
  .btn-outline {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    background-color: transparent;
    transition: background-color 0.2s ease, color 0.2s ease;
    text-transform: uppercase;
    padding: 0.5em 1em;
    font-size: 1.4vw;
    font-weight: bold;
    margin-top: 2vw;
    @include media(md) {
      padding: 1em 1.5em;
      height: 40px;
      border-width: 3px;
      font-size: 1.3vw;
    }
    &:hover {
      background-color: black;
      border-color: black;
      color: #fff;
    }
    &:disabled {
      cursor: default;
      color: black;
      background-color: transparent;
      opacity: 0.5;
    }
  }
  &__content {
    position: absolute;
    height: 100%;
    flex-grow: 1;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__img {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.init-game-dialog {
  text-transform: uppercase;
  height: 22vw;
  display: flex;
  justify-content: space-between;
  padding-top: 17%;
  padding-left: 20%;
  padding-right: 20%;
  text-align: center;
  &__header-1 {
    font-size: 4vw;
    margin-bottom: 0.2em;
    font-weight: 600;
    @include media(md) {
      font-size: 1.4vw;
    }
  }
  &__header-2 {
    margin: 0.1em 0 0.2em;
    font-size: 3vw;
    @include media(md) {
      font-size: 1vw;
    }
  }
  &__header-3 {
    margin: 0.05em 0 0.1em;
    font-size: 2vw;
    @include media(md) {
      font-size: 0.7vw;
    }
  }
  &__btn.btn-outline {
    margin: 0.2em 0.5em;
    font-size: 2.2vw;
    padding: 0.5em 1em;
    border-color: currentColor;
    @include media(md) {
      font-size: 0.8vw;
    }
  }
}
