@use '../../../utils/styles/default.scss';
@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.info-section {
  padding: 10% 0 0;
  background-color: #060606;
  padding-bottom: 3rem;
  @include media(xxxl) {
    padding-top: 3%;
  }
  &-item {
    margin-bottom: 2rem;
    &__title {
      color: $blue;
    }
    &__text {
      color: $white;
    }
  }
}
