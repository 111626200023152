.full-width-notification {
  width: 100%;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.loading-notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
  }
  &.loading-notification &__img {
    width: 10rem;
    height: 10rem;
    z-index: 6;
  }
}
